import React from "react";
import "../styles/home.css";
import Logo from "../assets/logo.png";

function TopTable({ data }) {
  const rows = [
    { name: "Total Users", value: data.totalUsers },
    { name: "Total Products", value: data.totalProducts },
    { name: "Total Swops", value: data.totalSwops },
    { name: "Total Swipes", value: data.totalSwipes },
    { name: "Right Swipes", value: data.rightSwipes },
    { name: "Left Swipes", value: data.leftSwipes },
    { name: "Users in 24 hrs", value: data.usersCreatedLast24Hours },
    {
      name: "Products in 24 hrs",
      value: data.productsCreatedLast24Hours,
    },
    {
      name: "Chats",
      value: data.chats,
    },
    {
      name: "Chats in 24 hrs",
      value: data.chatsIn24Hrs,
    },
    {
      name: "Product Views",
      value: data.productViews,
    },
    {
      name: "Total referrals",
      value: data.referrals,
    },
    {
      name: "Positive feedbacks",
      value: data.thumbsUp,
    },
    {
      name: "Negative feedbacks",
      value: data.thumbsDown,
    },
    {
      name: "Total saved searches",
      value: data.totalSearches,
    },
    {
      name: "Total saved searches in 24hrs",
      value: data.totalSearches24hrs,
    },
    {
      name: "All chats",
      value: data.totalMessages,
    },
  ];

  return (
    <div>
      <div className="stats">
        <img src={Logo} alt="logo" className="logo" />
        <div>Latest stats</div>
      </div>
      <div className="parent-table">
        {/* <TableContainer component={Paper}>
          <Table sx={{ minWidth: 250 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Stats</StyledTableCell>
                <StyledTableCell>Value</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <StyledTableRow key={row.name}>
                  <StyledTableCell component="th" scope="row">
                    {row.name}
                  </StyledTableCell>
                  <StyledTableCell>{row.value}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer> */}

        {rows.map((row) => (
          <div className="grid-item">
            <div className="grid-item-1">{row.name}</div>
            <div div className="grid-item-2">
              {row.value}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default TopTable;
